import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import ProgramLinks from '../components/ProgramLinks'

const Landing = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - Manicuring</title>
      <meta name="description" content="Landing Page" />
    </Helmet>

    <BannerLanding title="Manicuring" color="manicuring" />

    <div id="main">
      <section id="one">
        <div className="inner">
          <p>Program Level - Undergraduate certificate</p>
          <p>Program Length - 4 months</p>
          <header className="major">
            <h2>Performance Objectives</h2>
          </header>
          <ul>
            <li>
              Acquire knowledge of laws and rules regulating California’s
              cosmetology establishment practices.
            </li>
            <li>
              Acquire the knowledge of sanitation and sterilization as related
              to all functions of Manicuring.
            </li>
            <li>
              Acquire the knowledge of subjects relative to Manicuring including
              anatomy, physiology, chemistry and the theory relative to the
              practical procedures to be performed.
            </li>
          </ul>
          <header className="major">
            <h2>Skills to be Developed</h2>
          </header>
          <ul>
            <li>
              The proper use of implements relative to all Manicures, and
              Pedicures.
            </li>
            <li>A practical ability to perform Manicures, and Pedicures.</li>
            <li>
              Develop the knowledge and confidence to safely perform all
              operations authorized for a Manicurist.
            </li>
          </ul>
          <header className="major">
            <h2>Attitudes and Appreciations to be Developed</h2>
          </header>
          <ul>
            <li>
              Learn to appreciate good workmanship common to practicing
              Cosmeticians.
            </li>
            <li>
              Develop a positive attitude toward the public and your fellow
              workers.
            </li>
            <li>Learn and Appreciate honesty and integrity.</li>
            <li>
              Learn to act professionally, especially when dealing with patrons
              and colleagues.
            </li>
          </ul>
          <header className="major">
            <h2>Career Opportunities for Manicurists and Job Outlook </h2>
          </header>
          <p>
            Thanh Le College, School of Cosmetology graduates have found careers
            as:
          </p>
          <ul>
            <li>Owners of a salon or spa</li>
            <li>Managers of a salon or spa•</li>
            <li>And more!</li>
          </ul>
        </div>
      </section>
      <ProgramLinks ge="manicuring" />
    </div>
  </Layout>
)

export default Landing
